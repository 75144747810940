import localeAntd from 'antd/es/locale/es_ES'

const messages = {
  'topBar.issuesHistory': 'Problemas históricos',
  'topBar.projectManagement': 'Administración de Proyecto',
  'topBar.typeToSearch': 'Búsqueda...',
  'topBar.findPages': 'Encontrar páginas...',
  'topBar.actions': 'Acciones',
  'topBar.status': 'Estado',
  'topBar.profileMenu.hello': 'Hola',
  'topBar.profileMenu.billingPlan': 'Pagos',
  'topBar.profileMenu.role': 'Rol',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Teléfono',
  'topBar.profileMenu.editProfile': 'Editar perfil',
  'topBar.profileMenu.logout': 'Salir',
  'topBar.profileMenu.cambiarClave': 'Cambiar contraseña',
}

export default {
  locale: 'en-MX',
  localeAntd,
  messages,
}
