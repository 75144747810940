import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
// import apiLocation from './apiLocation.json'

console.log(process.env.NODE_ENV,process.env.REACT_APP_API_URL)
export const apiURLBase = process.env.REACT_APP_API_URL?process.env.REACT_APP_API_URL:'http://localhost:4100'; 
  
  // process.env.NODE_ENV === 'production' ? apiLocation.production : apiLocation.development

  // "development":"http://62db786d5ee7.ngrok.io",

export const apiClient = axios.create({
  baseURL: apiURLBase,
  timeout: 25000,
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('t')
  if (accessToken) {
    // request.headers.Authorization = `Bearer ${accessToken}`
    // request.headers.AccessToken = accessToken
    request.headers.auth=accessToken;
  }
  return request
})

apiClient.interceptors.response.use(
  response =>{
    const { t } = response.headers
    if (t) {
      localStorage.setItem('t', t)
    }
    return response
  }, 
  error => {
  // Errors handling
  console.log("error ApiClient error",{error})
  const { response } = error
  const { data } = response??{data:undefined}
  // console.log("error ApiClient",data)
  if (data) {
    notification.error({
      message: data.message,
    })
  } else {
    notification.error({
      message: error.message,
    })
  }
  return error;
})

// export default apiClient
export class PeticionBase {
  static listadoSimple = portionEndPoint =>
    new Promise((resolve, reject) => {
      apiClient({
        method: 'get',
        url: `/${portionEndPoint}/list-names`,
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static listado = (portionEndPoint, parametros = {}) =>
    new Promise((resolve, reject) => {
      if (process.env.NODE_ENV !== 'production') console.log('---PARAMETROS listado', parametros)
      apiClient({
        method: 'post',
        url: `/${portionEndPoint}/list-antd`,
        data: {
          ...parametros,
        },
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static obtenerId = (portionEndPoint, id) =>
    new Promise((resolve, reject) => {
      apiClient({
        method: 'get',
        url: `/${portionEndPoint}/${id}`,
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static editar = (portionEndPoint, parametros = {}) =>
    new Promise((resolve, reject) => {
      if (process.env.NODE_ENV !== 'production') console.log('---PARAMETROS editar', parametros)
      apiClient({
        method: 'put',
        url: `/${portionEndPoint}/edit`,
        data: {
          ...parametros,
        },
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static nuevo = (portionEndPoint, parametros = {}) =>
    new Promise((resolve, reject) => {
      if (process.env.NODE_ENV !== 'production') console.log('---PARAMETROS nuevo', parametros)
      apiClient({
        method: 'post',
        url: `/${portionEndPoint}/new`,
        data: {
          ...parametros,
        },
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static habilitar = (portionEndPoint, id) =>
    new Promise((resolve, reject) => {
      apiClient({
        method: 'put',
        url: `/${portionEndPoint}/enable/${id}`,
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static inhabilitar = (portionEndPoint, id) =>
    new Promise((resolve, reject) => {
      apiClient({
        method: 'put',
        url: `/${portionEndPoint}/disable/${id}`,
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static finalizar = (portionEndPoint, id) =>
    new Promise((resolve, reject) => {
      apiClient({
        method: 'put',
        url: `/${portionEndPoint}/closure/${id}`,
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static generalPut = (portionEndPoint, endEndPoint, parametros = {}) =>
    new Promise((resolve, reject) => {
      if (process.env.NODE_ENV !== 'production') console.log('---PARAMETROS PUT', parametros)
      apiClient({
        method: 'put',
        url: `/${portionEndPoint}/${endEndPoint}`,
        data: {
          ...parametros,
        },
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static generalPost = (portionEndPoint, endEndPoint, parametros = {}) =>
    new Promise((resolve, reject) => {
      if (process.env.NODE_ENV !== 'production') console.log('---PARAMETROS POST', parametros)
      apiClient({
        method: 'post',
        url: `/${portionEndPoint}/${endEndPoint}`,
        data: {
          ...parametros,
        },
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static generalPostBlob = (portionEndPoint, endEndPoint, parametros = {}) =>
    new Promise((resolve, reject) => {
      if (process.env.NODE_ENV !== 'production') console.log('---PARAMETROS POST', parametros)
      apiClient({
        method: 'post',
        url: `/${portionEndPoint}/${endEndPoint}`,
        data: {
          ...parametros,
        },
        responseType: 'blob',
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static generalGet = (portionEndPoint, endEndPoint, param) =>
    new Promise((resolve, reject) => {
      apiClient({
        method: 'get',
        url: `/${portionEndPoint}/${endEndPoint}/${param}`,
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static generalGetNoParam = (portionEndPoint, endEndPoint) =>
    new Promise((resolve, reject) => {
      apiClient({
        method: 'get',
        url: `/${portionEndPoint}/${endEndPoint}`,
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })

  static generalDelete = (portionEndPoint, endEndPoint, parametros) =>
    new Promise((resolve, reject) => {
      apiClient({
        method: 'delete',
        url: `/${portionEndPoint}/${endEndPoint}`,
        data: {
          ...parametros,
        },
      })
        .then(resp => resolve(resp.data))
        .catch(error => reject(error))
    })
}
