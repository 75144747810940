import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import classNames from 'classnames'
import TopBar from 'components/cleanui/layout/TopBar'
import Breadcrumbs from 'components/cleanui/layout/Breadcrumbs'
import Menu from 'components/cleanui/layout/Menu'
import Footer from 'components/cleanui/layout/Footer'
import Sidebar from 'components/cleanui/layout/Sidebar'
import ManejandoErrores from './manejandoErrores'
// import SupportChat from 'components/cleanui/layout/SupportChat'



const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const MainLayout = ({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
}) => {
  return (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        <Sidebar />
        {/* <SupportChat /> */}
        <Menu />
        <Layout>
          <Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
          >
            <TopBar />
          </Layout.Header>
          <Breadcrumbs />
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <ManejandoErrores>{children}</ManejandoErrores>
            {/* <div className="cui__utils__content">{children}</div> */}
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))


// import React from "react";
// import ReactDOM from "react-dom";
// import MagicBell, { FloatingNotificationInbox } from "@magicbell/magicbell-react";

// const theme = {"icon":{"borderColor":"#ccc","width":"24px"},"unseenBadge":{"backgroundColor":"#DF4759"},"header":{"backgroundColor":"#4b7cf3","textColor":"#ffffff","borderRadius":"16px","fontFamily":"Verdana"},"footer":{"backgroundColor":"#4b7cf3","textColor":"#ffffff","borderRadius":"16px","fontFamily":"Verdana"},"notification":{"default":{"textColor":"#595c97","borderRadius":"8px","backgroundColor":"#4b7cf3","fontFamily":"Verdana"},"unseen":{"backgroundColor":"#4b7cf3","textColor":"#595c97","borderRadius":"8px","fontFamily":"Verdana"},"unread":{"backgroundColor":"#4b7cf3","textColor":"#595c97","borderRadius":"8px","fontFamily":"Verdana"}}};

// /**
//  * You can use userExternalId instead of the userEmail - https://bit.ly/3oiDSAe
//  */

// ReactDOM.render(
  // <MagicBell
  //   apiKey="a63378966d8f0afb8a3802e9b37f1e45176897a4"
  //   userEmail="cesar.gv@exogrid.io"
  //   userKey="hjU13gQmpA5TgE0+6cvTW3sTIysZvOO+DCK7Rycj8XI="
  //   theme={theme}
  // >
  //   {(props) => <FloatingNotificationInbox width={400} height={500} {...props} />}
  // </MagicBell>,
//   document.body,
// );